import React from 'react'
import {monthNamesShort} from '../../../names'

const Month = ({data, style, tabIndex}) => {
    return(
        <p className={`homepage--timeline-datewrap`} data-time={data.time} key={data.time} {...style} {...tabIndex}>
            {monthNamesShort[data.month]}
        </p>
    )
}

export default Month