import React from 'react'
import TimeSlider from './timeline/TimeSlider'
import MainSlider from './timeline/MainSlider'

class Timeline extends React.Component {
    constructor(){
        super()
        this.handlePosOut = this.handlePosOut.bind(this)
        this.handleTimeOut = this.handleTimeOut.bind(this)
        this.toggleList = this.toggleList.bind(this)
        this.state = {
            feed: null,
            loading: true,
            data: null,
            position: null,
            showList: false
        }
    }

        
    
    componentDidMount() {
        // if data in storage, skip sorting 
        if (sessionStorage.getItem("timeline") == null) {
            this.organizeData()
        } else {
            this.getData(0)
        }
    }

    componentDidUpdate(prevProps) {
        this.countPosition()
    }


    organizeData() {

        const feed = []
        const {feedRaces, feedNews} = this.props

        feedRaces.map(
            ({node}) => (
                feed.push({
                    title: node.race_title,
                    url: node.race_url,
                    from: new Date(node.from.replace(/-/g, '/')).getTime(),
                    type: "race",
                    text: node.text 
                        ? node.text 
                        : node.circuit.descr, 
                    image: node.image,
                    to: new Date(node.to.replace(/-/g, '/')).getTime(), 
                    map: node.circuit.circuit_map,
                    circuitName: node.circuit.name + " (" + node.circuit.country + ")",
                    cancelled: node.cancelled,
                    race_type: node.type.type_name,
                    car_types: node.car_types
                })
            )
        )
        
        feedNews.map(
            ({node}) => (
                feed.push({
                    title: node.name,
                    url: node.url,
                    from: new Date(node.posted_on.replace(/-/g, '/')).getTime(),
                    type: node.type,
                    text: node.catch_text != null ? "<p>" + node.catch_text  + "</p>" : node.text, 
                    image: node.placeholder_image,
                    fb_url: node.fb_permalink_url
                })
            )
        )

        // sort by 2nd
        feed.sort((a,b) => a.from > b.from)
        // stringify and push into session storage
        const feedJSON = JSON.stringify(feed)
        sessionStorage.setItem("timeline", feedJSON)
        this.getData(feed)
    }


    getData(feed) {
        if (sessionStorage.getItem("timeline") == null) {
            this.setState({data: feed})
        } else {
            this.setState({data: JSON.parse(sessionStorage.getItem("timeline"))}) 
        }
        this.setState({loading: false})
    }


    countPosition() {
        if(this.state.data !== null && this.state.position === null) {
            const diffdate = new Date().getTime();
            let racesDates = {}
    
            for (const object of this.state.data) {
                const difference = object.from - diffdate
                racesDates[object.from] = Math.abs(difference)
            }
    
            const positionDate = Object.keys(racesDates).reduce(function (r, a, i) {
                return !i || +racesDates[a] < +racesDates[r] ? a : r;
            }, undefined)
    
            this.setState({position: positionDate})
        }
    }

    handlePosOut = (data) => {
        let time
        if(this.state.data && data !== null && this.state.data.length >= data) {
            time = parseInt(this.state.data[data].from)
        }
        this.state.position !== time && time !== undefined && this.setState({position: time})
    }

    handleTimeOut = (data) => {
        if(data !== this.state.position) {
            this.setState({position: data})
        }
    }

    toggleList() {
        this.setState(prevState => ({
            showList: !prevState.showList
        }))
    }

    render() {
        return(
            <section className="homepage--timeline">
                <section className="homepage--timeline-wrapper" id="timelineWrapper">
                    <h2 className="homepage--timeline-heading"><span className="homepage--timeline-toggle" onClick={this.toggleList} onKeyDown={this.handleClick} role="button" tabIndex="0" aria-label="Tidslinje toggle"></span>Tidslinje</h2>
                    <div style={{position: 'relative'}}>
                        {this.state.position !== null && 
                            <MainSlider data={this.state.data} loading={true} position={this.state.position} onPosOut={this.handlePosOut}/>
                        }
                    </div>
                </section>

                <section className={`homepage--timeline-timewrap${this.state.showList ? " show" : "" }`}>
                    <div className="homepage--timeline-timeclose" onClick={this.toggleList} onKeyDown={this.handleClick} role="button" tabIndex="0">
                        <span className="cross"></span>
                    </div>
                    <TimeSlider loading={this.state.loading} data={this.state.data} position={this.state.position} timeOut={this.handleTimeOut}/>
                </section>
            </section>
            
        )
    }
}


export default Timeline


