import React from 'react'
import '../../styles/youtube-intro.css'
import Vimeo from '@u-wave/react-vimeo';
import Img from 'gatsby-image'


class IntroVideo extends React.Component {
  
  constructor(props){
    super(props);

    this.state = {
      opacitySet: 1
    }
  }

  fadeIntoVideo = () => {
    this.setState({
      opacitySet: 0
    })
  }

  render() {
    const {data} = this.props
    return (
      <div className="grid--margin intro" key={data.id} style={{
        padding: '7em 0',
        position: 'relative',
        marginTop: '-10vw'
      }}>
        <h1>{data.heading}</h1>
        <p className="p1" style={{
          marginLeft: 0,
        }}>{data.text}</p>
        <div className="video-background">
          <div className="video-foreground">
            <Img fluid={data.image.localFile.childImageSharp.fluid} style={{
              height: '100%',
              backgroundSize: 'cover',
              position: 'sticky',
              left: 0,
              width: '100vw',
              zIndex: 1,
              transition: 'opacity 0.4s ease 0.35s',
              opacity: this.state.opacitySet
            }} />
            {/* <YouTube 
              videoId="2zdE_vneNdw"
              opts={{
                playerVars: {
                  autoplay: true,
                  loop: true,
                  controls: false,
                  mute: true,
                  listType: "playlist"
                }
              }}
              onReady={this.fadeIntoVideo}
            /> */}
            <Vimeo
              video="https://vimeo.com/441949227/a1fa28a2d9"
              autoplay
              loop
              background
              controls={false}
              onReady={this.fadeIntoVideo}
              responsive={true}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default IntroVideo
