import React from 'react'
import Slider from "react-slick";


const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 1,
    initialSlide: 1,
    swipeSlide: true,
    variableWidth: true,
    responsive: [
        {
            breakpoint: 900,
            settings: {
                slidesToShow: 1,
                centerMode: false,
                variableWidth: true,
                arrows: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                variableWidth: true,
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '20px',
                arrows: true
            }
        }
    ]
}


const MainSliderLoader = () => (
    <div className="homepage--timeline-loader homepage--timeline-inner">
        <Slider {...settings}>
            <div
                className={ `article` } 
                draggable="false"
            >
                <div className="gatsby-image-wrapper"></div>
            </div>
            <div
                className={ `article` } 
                draggable="false"
            >
                <div className="gatsby-image-wrapper"></div>
            </div>
            <div
                className={ `article` } 
                draggable="false"
            >
                <div className="gatsby-image-wrapper"></div>
            </div>
            <div
                className={ `article` } 
                draggable="false"
            >
                <div className="gatsby-image-wrapper"></div>
            </div>
        </Slider>
    </div>
)

export default MainSliderLoader