import React from 'react'
import {Link} from 'gatsby'
import {pad} from '../../names'
import MapImage from './article/MapImage';
import InnerCntdwn from './article/InnerCntdwn'
import InfoBar from './article/InfoBar';
import Text from './article/Text';


class MainSliderArticle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            comp: {},
            loading: true,
        }
    }
    handleOnMouseDown (e) {
        this.setState({
          clientXonMouseDown: e.clientX,
          clientYonMouseDown: e.clientY
        })
        e.preventDefault() // stops weird link dragging effect
    }
    
    handleOnClick (e) {
        e.stopPropagation()
        if (this.state.clientXonMouseDown !== e.clientX || this.state.clientYonMouseDown !== e.clientY) {
            e.preventDefault()
        }
    }

    componentDidMount() {
        const {data} = this.props

        let link

        if(data.type === "race") {
            link = data.url 
                ? `/race/${new Date(data.from).getFullYear()}/${pad(new Date(data.from).getMonth()+1)}/${data.url}` 
                : "/"
        } else if (data.type === "news") {
            link = data.url 
                ? `/${new Date(data.from).getFullYear()}/${pad(new Date(data.from).getMonth()+1)}/${data.url}` 
                : "/"
        } else if (data.type === "facebook") {
            link = data.fb_url
        } else {
            link = data.url
        }

        this.setState({
            data: data,
            comp: {
                link: link
            },
            loading: false
        })
    }

    loaded(i) {
        this.props.loadedChild(i)
    }

    isExternal = (url) => {
        var match = url.match(/^([^:?#]+:)?(?:\/\/([^?#]*))?([^?#]+)?(\?[^#]*)?(#.*)?/);
        if (typeof match[1] === "string" && match[1].length > 0 && match[1].toLowerCase() !== document.location.protocol) return true;
        if (typeof match[2] === "string" && match[2].length > 0 && match[2].replace(new RegExp(":("+{"http:":80,"https:":443}[document.location.protocol]+")?$"), "") !== document.location.host) return true;
        return false;
    }
  
    render() {
        return(
            !this.state.loading
            ? this.state.data.type !== "facebook"
                ? ( 
                    <Link 
                        style={this.props.style}
                        to={ this.state.comp.link }
                        className={ `article loading` } 
                        data-time={this.state.data[2]} 
                        draggable="false" 
                        onMouseDown={e => this.handleOnMouseDown(e)}
                        onClick={e => this.handleOnClick(e)}
                        onLoad={() => this.loaded(this.props.index)}
                    >
                        <MapImage map={this.state.data.map} image={this.state.data.image} />
                        <InnerCntdwn type={this.state.data.type} start={this.state.data.from} end={this.state.data.to || this.state.data.from}  cancelled={this.state.data.cancelled}/>
                        <InfoBar type={this.state.data.type} start={this.state.data.from} end={this.state.data.to || this.state.data.from} />
                        <h3>{`${this.state.data.race_type ? `${this.state.data.race_type}:` : ""} ${this.state.data.title}`}</h3>
                        <Text text={this.state.data.text} /> 
                        {this.state.data.car_types && <p><b>Køret med: </b>{this.state.data.car_types.map(e => e.car_type).join(", ")}</p>}
                    </Link>
                )
                :
                (
                    <a href={this.state.comp.link} target="_blank" rel="noreferrer"
                        className={ `article loading` } 
                        data-time={this.state.data[2]} 
                        draggable="false" 
                        onMouseDown={e => this.handleOnMouseDown(e)}
                        onClick={e => this.handleOnClick(e)}
                        onLoad={() => this.loaded(this.props.index)}
                        style={this.props.style}
                    >
                        <MapImage map={this.state.data.map} image={this.state.data.image} />
                        <InnerCntdwn type={this.state.data.type} start={this.state.data.from} end={this.state.data.to || this.state.data.from}  cancelled={this.state.data.cancelled}/>
                        <InfoBar type={this.state.data.type} start={this.state.data.from} end={this.state.data.to || this.state.data.from} />
                        <h3>{`${this.state.data.race_type ? `${this.state.data.race_type}:` : ""} ${this.state.data.title}`}</h3>
                        <Text text={this.state.data.text} /> 
                        {this.state.data.car_types && <p><b>Køret med: </b>{this.state.data.car_types.map(e => e.car_type).join(", ")}</p>}
                    </a>
                )
            : (<p>Loading</p>)
        )
    }
}

export default MainSliderArticle