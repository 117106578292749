import 'core-js';
import React from "react"
import {Link, graphql} from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import "../styles/homepage.css"
import UpcomingRace from '../components/homepage/UpcomingRace';
import Timeline from '../components/homepage/timeline';
import IntroVideo from '../components/homepage/IntroVideo';

class IndexTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderIndex: false,
      nav1: null,
      nav2: null,
      activeSlide: 0,
      slickSlide: null,
      width: 0,
      background: "default",
      clientXonMouseDown: null,
      clientYonMouseDown: null,
      loaded: false,
      loadedPrevent: false
    }
  }
  
  componentDidMount() {
    this.setState({
      background: "didmount"
    });
  }


  render() {
    const {
      data
    } = this.props
    
    return(
    <>
      <SEO title="Home" />
      <section id="homepage">

      <IntroVideo data={data.directusIntro} />

      {/* <Announcement data={data.allDirectusAnnouncement.edges} /> */}
      <Timeline feedRaces={data.allDirectusRace.edges} feedNews={data.allDirectusNews.edges} />

      {/* UPCOMING RACE*/}
      <UpcomingRace feed={data.allDirectusRace.edges} />
      
      <section className="homepage--links">
        {data.allDirectusMenu.edges.map(edge => (
          <article key={edge.node.id}>
            <Link to={edge.node.link_to ? "/" +edge.node.link_to : "/"} className="click"><Img fluid={edge.node.background_image.localFile.childImageSharp.fluid} className="opaque50 homepage--links-image" /></Link>
            <Link to={edge.node.link_to ? "/" +edge.node.link_to : "/"} className="click"><h2>{edge.node.homepage_name ? edge.node.homepage_name : edge.node.name}</h2></Link>
            <Link to={edge.node.link_to ? "/" +edge.node.link_to : "/"} className="click"><p className="opaque80">{edge.node.description}</p></Link>
            <Link to={edge.node.link_to ? "/" +edge.node.link_to : "/"} className="click homepage--links-link">{edge.node.link_catch}</Link>
          </article>
        ))}
        </section> 
      </section>
    </>
  )
  }
}

export default IndexTest

export const query = graphql`
  query {
    directusIntro {
      id
      heading
      text
      image {
        localFile {
          childImageSharp {
              fluid(quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp                
                  }
              }
          }
      }
    },
    allDirectusRace (
        sort: {
            order: DESC, 
            fields: from
        }, 
        limit: 1000, 
        filter: {
            status: {eq: "published"}
        }) {
        edges {
            node {
                id
                race_title
                race_url
                cancelled
                from
                to
                text
                type {
                  type_name
                }
                car_types {
                  car_type
                }
                circuit {
                    descr
                    country
                    name
                    circuit_map {
                        localFile {
                            childImageSharp {
                                fluid(quality: 25, maxWidth: 400) {
                                    ...GatsbyImageSharpFluid_withWebp                
                                }
                            }
                        }
                    }    
                }  
                image{
                    localFile {
                        childImageSharp {
                            fluid(quality: 50, maxWidth: 400) {
                                ...GatsbyImageSharpFluid_withWebp                
                            }
                        }
                    }
                }
            }
        }
    }
    allDirectusNews(sort: {order: DESC, fields: posted_on}, limit: 1000, filter: {status: {eq: "published"}}) {
        edges {
            node {
                id
                name
                url
                posted_on
                catch_text
                text
                type
                fb_permalink_url
                placeholder_image{
                    localFile {
                        childImageSharp {
                            fluid(quality: 55, maxWidth: 500) {
                                ...GatsbyImageSharpFluid_withWebp                
                            }
                        }
                    }
                }
            }
        }
    },
    allDirectusMenu(filter: {homepage: {eq: true}}, sort: {fields: homepage_sort}) {
      edges {
        node {
          link_to
          name
          homepage_name
          background_image {
            localFile {
              childImageSharp {
                fluid(quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp                
                }
              }
            }
          }
          description
          id
          link_catch
        }
      }
    },
    allDirectusAnnouncement(sort: {fields: expiry_date}) {
      edges {
        node {
          text
          type
          id
          expiry_date
          article {
            url
            posted_on
            name
          }
        }
      }
    }
  }
`