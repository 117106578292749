import React from 'react'
import Img from 'gatsby-image'
import missingPicture from './../../../../images/missing-picture.jpg'

class MapImage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            loading: true
        }
    }
    
    componentDidMount() {
    }

    render() {
        const {map, image} = this.props

        return(
            <div style={{overflow: 'hidden'}} className="no-gatsby-image">
            {image 
                ? 
                    map ? <>
                        {map && <Img fluid={map.localFile.childImageSharp.fluid} className={ `image-map`} />}
                        {image && image.localFile 
                                ? <Img fluid={image.localFile.childImageSharp.fluid} className={ `opaque50 homepage--image-blur` } /> 
                                :  <img src={image} className={ `opaque50 `} alt="From Facebook"/>   }
                    </>
                    : image && image.localFile
                        ? <Img fluid={image.localFile.childImageSharp.fluid} className={ `opaque50 `} />
                        : <img src={image} className={ `opaque50 `} alt="From Facebook"/>
                : <img src={missingPicture} className={ `opaque50 `} alt="Missing media placeholder"/>
            }
            </div>
        )
    }
}

export default MapImage