import React from 'react'
import Slider from "react-slick";
import MainSliderArticle from './MainSliderArticle';
import MainSliderLoader from './article/MainSliderLoader';


class MainSlider extends React.Component {
    state = {
        data: null,
        loading: true,
        position: null,
        positionIndex: null,
        loadedAmount: 0,
        max: null,
        settings: {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 2,
            initialSlide: 0,
            swipeToSlide: true,
            arrows: true,
            afterChange: (current) => {
                this.sendOut(current)
                sessionStorage.setItem("positionIndex", current)
                this.setState({positionIndex: current})
            },
            swipeSlide: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        variableWidth: true,
                        arrows: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        variableWidth: true,
                        slidesToShow: 1,
                        centerMode: true,
                        centerPadding: '20px',
                        arrows: true
                    }
                }
            ]}
    }
    componentDidMount() {
        // rerender if slider doesn't exist
        if(!this.slider) {
            this.setState({loading: true})
        }




        this.setState({
            data: this.props.data,
            position: this.props.position,
            positionIndex: sessionStorage.getItem("positionIndex"),
            max: this.props.data.length - 1,
            
        })


        


    }

    sendOut(data) {
        this.props.onPosOut(data)
    }

    updateView(position) {
        const that = this
        
        for (var i = 0; i < this.props.data.length; i++) {
            if(this.props.data[i].from === parseInt(position)) {

                if(this.props.data.length - 1 === i  && window.innerWidth >= 601) {
                    that.slider.slickGoTo(i - 1, false)
                } else {
                    that.slider.slickGoTo(i, false)
                }
                // if (this.state.positionIndex !== i) {
                
                break;
            }
        }

    }

    componentDidUpdate(prevProps) {
        const activeSlick = document.querySelectorAll(".slick-slide[aria-hidden=false]")[0]

        if(activeSlick.dataset.index !== sessionStorage.getItem("positionIndex")) {
            if(parseInt(sessionStorage.getItem("positionIndex")) === this.state.max  && window.innerWidth >= 601) {
                this.slider.slickGoTo(sessionStorage.getItem("positionIndex") - 1, false)
            } else {
                this.slider.slickGoTo(sessionStorage.getItem("positionIndex"), false)
            }
            this.sendOut(sessionStorage.getItem("positionIndex"))
        }

        let thisMonth = new Date(this.props.position).getMonth()
        let prevMonth = new Date(prevProps.position).getMonth()

        if(this.props.position !== prevProps.position && thisMonth !== prevMonth) {
            this.updateView(this.props.position)
        }


        if(sessionStorage.getItem("positionIndex") !== null && this.slider && this.state.loading)  {
            const newSettings = this.state.settings
            newSettings.initialSlide = sessionStorage.getItem("positionIndex")

            this.setState({settings: newSettings})
            if(parseInt(sessionStorage.getItem("positionIndex")) === this.state.max  && window.innerWidth >= 601) {
                this.slider.slickGoTo(sessionStorage.getItem("positionIndex") - 1, false)
            } else {
                this.slider.slickGoTo(sessionStorage.getItem("positionIndex"), false)
            }

            this.sendOut(sessionStorage.getItem("positionIndex"))
            this.state.loading && this.setState({loading: false})
        }
        
        if(sessionStorage.getItem("positionIndex") === null && this.slider && this.state.loading) {
            const newSettings = this.state.settings
            newSettings.initialSlide = this.state.positionIndex
            
            this.setState({settings:newSettings})
            this.state.loading && this.setState({loading: false})
        }


        

        if (this.state.positionIndex === null && sessionStorage.getItem("positionIndex") === null) {
            const {position, data} = this.props

            this.setState({data: data})
            const that = this

            for (var i = 0; i < data.length; i++) {
    
                if (data[i].from === parseInt(position)) {
                    that.setState({positionIndex: i})

                    if(data.length - 1 === i && window.innerWidth >= 601) {
                        that.slider.slickGoTo(i - 1, false)
                    } else {
                        that.slider.slickGoTo(i, false)
                    }
    
                    that.props.onPosOut(i)
                    break;
                }
            }
        } 

        
        
    }

    countLoaded = (e) => {
        const el = document.querySelectorAll('.slick-slide[data-index="' + e + '"] .article')[0]
        el.classList.remove("loading")
    }
    
    render() {

        
        const {data, loading} = this.state

        
        return(
            data !== null ? (
                <React.Fragment>
                    <Slider ref={slider => (this.slider = slider)} {...this.state.settings} className={`homepage--timeline-inner ${loading && " loading"}`}>
                        {data.map(
                            (single, index) => 
                                <MainSliderArticle 
                                    data={single} 
                                    key={index} 
                                    loadedChild={this.countLoaded} 
                                    index={index}
                                />
                        )}
                    </Slider>
                    {loading && <MainSliderLoader />}
                </React.Fragment>
            )
            : <p>Loading</p>
        )
    }
}

export default MainSlider

