import React from 'react'
import {replaceWords, monthNamesShort} from '../../../names'

const InfoBar = ({type, start, end}) => {

    return(
        <p className="homepage--timeline-infobar">
            <span className={ `homepage--timeline-type ${type}`}>{ replaceWords(type)} </span>

            <span className="homepage--timeline-date">{ `${
            new Date(start).getMonth() === new Date(end).getMonth() 
            ? new Date(start).getDate() === new Date(end).getDate() 
                ? `${new Date(start).getDate()} ${monthNamesShort[new Date(start).getMonth()]} ${new Date(start).getFullYear() - 2000}` 
                : `${new Date(start).getDate()} - ${new Date(end).getDate()} ${monthNamesShort[new Date(start).getMonth()]} ${new Date(start).getFullYear() - 2000}` 
            : `${new Date(start).getDate()} ${monthNamesShort[new Date(start).getMonth()]} - ${new Date(end).getDate()} ${monthNamesShort[new Date(end).getMonth()]} ${new Date(start).getFullYear() - 2000}`
            }`}</span>
        </p>
    )
}

export default InfoBar