import React from 'react'
import {regex} from '../../../names'

const Text = ({text}) => {

    return (
        text.length > 130 
        ? <p dangerouslySetInnerHTML={{__html:text.replace(regex, '').substring(0, 130).substr(0, Math.min(text.replace(regex, '').substring(0, 130).length, text.replace(regex, '').substring(0, 130).lastIndexOf(" "))) +" ..."}}></p>
        : <p dangerouslySetInnerHTML={{__html:text}}></p>
    )
}

export default Text