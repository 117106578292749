import React from 'react'
import Slider from "react-slick";
import {pad} from '../../names'
import Year from './time/Year'
import _ from 'lodash'
import Month from './time/Month';


class TimeSlider extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            position: null
        }
        
    }
    
    UNSAFE_componentWillReceiveProps() {
        this.createObject()
    }

    createObject() {
        if(this.props.data !== null && this.state.data === null) {

            const {data} = this.props
            let thisObj = []

            for (const race of data) {
                const date = race.from
                const month = pad(new Date(date).getMonth())
                const year = new Date(date).getFullYear()

                let yearExists = false
                let monthExists = false

                _.find(thisObj, (o => {
                    if(o.year === year && o.type === "year") {
                        yearExists = true
                    }
                    if(o.year === year && o.month === parseInt(month) && o.type === "month") {
                        monthExists = true
                    }
                }))

                if(!yearExists) {
                    thisObj.push({year: year, time: 0, type: 'year'})
                }
                
                if(!monthExists) {
                    thisObj.push({month: parseInt(month), year: year, time: date, type: 'month'})
                }
            }

            this.setState({data: thisObj})
        }
    }

    activeMonth() {
        for(var i = 0; i < this.state.data.length; i++) {
            const eachYear = new Date(this.state.data[i].time).getFullYear()
            const posYear = new Date(this.props.position).getFullYear()
            
            const eachMonth = new Date(this.state.data[i].time).getMonth()
            const posMonth = new Date(this.props.position).getMonth()

            if(eachYear === posYear && eachMonth === posMonth) {
                this.slider && 
                    this.slider.slickGoTo(i, false)
                break;
            }
        }
    }


    render() {
        this.state.data !== null && this.activeMonth()
        return(
               this.state.data !== null &&
                <Slider
                    infinite={false}
                    vertical={true}
                    verticalSwiping={true}
                    arrows={false}
                    centerMode={true}
                    ref={slider => (this.slider = slider)}
                    slidesToShow={1}
                    focusOnSelect={true}
                    beforeChange={(current, next) => {
                        this.props.timeOut(this.state.data[next].time)
                    }}
                    swipeToSlide={true}
                >
                    { this.state.data.filter(d => d.year || d.month).map(obj => {
                        return obj.type==="month"
                        ? <Month data={obj} key={obj} />
                        : <Year data={obj} key={obj} />
                        }
                    )}
                </Slider>
        )
    }
}

export default TimeSlider