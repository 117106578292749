import React from 'react'
import {Link} from 'gatsby'
import Img from 'gatsby-image'
import {pad, monthNamesShort} from '../names'
import Countdown from 'react-countdown-now'
import {rendererFull} from '../renderers'

const UpcomingRace = ({feed}) => {

    

    return(

        feed.sort((a, b) => (a.node.from > b.node.from) ? 1 : -1).filter(race => new Date(race.node.from).getTime() > new Date().getTime()).slice(0, 1).map(({node}) => (
        <section className="homepage--next" key={node.race_url}>
            <h3>Kommende løb</h3>
                <Link to={node.race_url ? `/race/${new Date(node.from).getFullYear()}/${pad(new Date(node.from).getMonth() + 1)}/${node.race_url}` : "/"} key={node.race_url} className="homepage--next-article click">
                <article>
                    <Img fluid={node.image.localFile.childImageSharp.fluid} className={ ` `} />
                    <div className="homepage--next-info">
                    <p className="homepage--next-name">{`${node.type?.type_name ? `${node.type.type_name}:` : ""} ${node.race_title}`}</p>
                    <p className="homepage--next-date">{ `${
                            new Date(node.from).getMonth() === new Date(node.to).getMonth() 
                            ? new Date(node.from).getDate() === new Date(node.to).getDate() 
                            ? `${new Date(node.from).getDate()} ${monthNamesShort[new Date(node.from).getMonth()]} ${new Date(node.from).getFullYear() - 2000}` 
                            : `${new Date(node.from).getDate()} - ${new Date(node.to).getDate()} ${monthNamesShort[new Date(node.from).getMonth()]} ${new Date(node.from).getFullYear() - 2000}` 
                            : `${new Date(node.from).getDate()} ${monthNamesShort[new Date(node.from).getMonth()]} - ${new Date(node.to).getDate()} ${monthNamesShort[new Date(node.to).getMonth()]} ${new Date(node.from).getFullYear() - 2000}`
                            }`}
                    </p>
                    <p className="homepage--next-place">{node.circuit.name}</p>
                    <div className="homepage--next-countdown">Løbet starter om <Countdown date={pad(new Date(node.from).getMonth()+1) + "/" + pad(new Date(node.from).getDate()) + "/" + new Date(node.from).getFullYear()+" "+ new Date(node.from).getHours()+":"+ pad(new Date(node.from).getMinutes()) + ":" + pad(new Date(node.from).getSeconds())} renderer={rendererFull} end={node.to}/></div>
                    <span className="homepage--next-link">Læs mere om løbet</span>
                    </div>
                </article>
                </Link>
        </section>
        ))


    )
}
export default UpcomingRace
