import React from 'react'
import Countdown from 'react-countdown-now'
import {pad} from '../../../names'
import {rendererFull} from '../../../renderers'

const InnerCntdwn = ({type, start, end, cancelled}) => {
    const date = 
        pad(new Date(start).getMonth()+1) + "/" + 
        pad(new Date(start).getDate()) + "/" + 
        new Date(start).getFullYear()+" "+ 
        new Date(start).getHours()+":"+ 
        pad(new Date(start).getMinutes()) + ":" + 
        pad(new Date(start).getSeconds())

        
    return(
        type === "race" && (
            <div className="cntdwn-wrapper">
            <Countdown date={date} className="homepage--timeline-race-countdown" renderer={rendererFull} end={end} cancelled={cancelled} />
            </div>
        ) 
    )
}

export default InnerCntdwn